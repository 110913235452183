import React, { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import { useUserContext } from '../../components/Shared/UserContext'; 
import { getAccountGetAccountDetails, usePostAccountToken } from '../../client/account';
import { GetAccountGetAccountDetailsParams, GetTokenRequest } from '../../model';
import { useNavigate } from 'react-router-dom';
import { login } from "@services/auth.service";
import { AxiosResponse } from "axios";
import "./Login.css";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { setCurrentUserContext } = useUserContext();
  const [signInData, setSignInData] = useState({
    username: '',
    password: '',
  });

  // Mutation hook for getting account token
  const getTokenMutation = usePostAccountToken({});

  // Function to handle sign-in form submission
  const handleSignIn = async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent default form submission
    try {
      const getTokenRequest: GetTokenRequest = {
        userEmail: signInData.username,
        password: signInData.password,
      };

      const response = await getTokenMutation.mutateAsync({
        data: getTokenRequest,
      });
      console.log('Token obtained successfully:', response);

      const getAccountDetailsRequest: GetAccountGetAccountDetailsParams = {
        userEmail: signInData.username,
        password: signInData.password,
      };

      const accountDetailsResponse: AxiosResponse<any> = await getAccountGetAccountDetails(getAccountDetailsRequest);

      login(signInData.username, signInData.password, response.data, accountDetailsResponse.data.userId);
      setCurrentUserContext(response.data);
      setSignInData({ username: '', password: '' });
      navigate('/ai-prediction');
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  const handleCreateAccount = () => {
    navigate('/create-account');
  };

  // Function to handle form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSignInData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="page">
      <Box className="container">
        <Typography className="title">
          Sign In
        </Typography>
        <form onSubmit={handleSignIn}>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Email"
            type="text"
            name="username"
            value={signInData.username}
            onChange={handleInputChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Password"
            type="password"
            name="password"
            value={signInData.password}
            onChange={handleInputChange}
            required
          />
          <div className="action-buttons-login">
            <Button
              type="submit" // Changed to 'submit' to trigger form submission
              variant="contained"
              color="primary"
            >
              Sign In
            </Button>
            <h2>or</h2>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleCreateAccount} 
            >
              Create account
            </Button>
          </div>
        </form>
      </Box>
    </div>
  );
};

export default Login;
