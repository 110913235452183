import axios from "axios";
import { GetTokenRequest } from "../model/getTokenRequest";
import { usePostAccountToken } from "../client/account";

const API_URL = "http://localhost:8080/api/auth/";

export const register = (username: string, email: string, password: string) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

export const login = (username: string, password: string, token: any, userId: string) => {
  localStorage.setItem("username", JSON.stringify(username));
  localStorage.setItem("password", JSON.stringify(password));
  localStorage.setItem("token", JSON.stringify(token));
  localStorage.setItem("userId", JSON.stringify(userId));
};

export const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("userId");
  localStorage.removeItem("username");
  localStorage.removeItem("password");
  localStorage.removeItem("token");

};

export const getCurrentUsername = () => {
  const userStr = localStorage.getItem("username");
  if (userStr) return JSON.parse(userStr);

  return null;
};
