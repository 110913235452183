// import { store, authActions } from '../store';

interface FetchWrapper {
    get: (url: string, body?: any) => Promise<any>;
    post: (url: string, body?: any) => Promise<any>;
    put: (url: string, body?: any) => Promise<any>;
    delete: (url: string, body?: any) => Promise<any>;
}

export const fetchWrapper: FetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method: string) {
    return (url: string, body?: any) => {
        const requestOptions: RequestInit = {
            method,
            headers: authHeader(url)
        };

        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }

        return fetch(url, requestOptions).then(handleResponse);
    };
}

// helper functions

function authHeader(url: string) {
    // return auth header with jwt if user is logged in and request is to the api url
    const token = authToken();
    // const isLoggedIn = !!token;
    const isApiUrl = url.startsWith(`http://localhost:5000` || '');
    
    if (isApiUrl) {
        return { Authorization: `Bearer ${token}` };
    } else {
        return {};
    }
}

function authToken() {
    // return store.getState().auth.user?.token;
}

function handleResponse(response: Response) {
    // return response.text().then(text => {
    //     const data = text && JSON.parse(text);

    //     if (!response.ok) {
    //         if ([401, 403].includes(response.status) && authToken()) {
    //             // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    //             const logout = () => store.dispatch(authActions.logout());
    //             logout();
    //         }

    //         const error = (data && data.message) || response.statusText;
    //         return Promise.reject(error);
    //     }

    //     return data;
    // });
}