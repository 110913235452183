import React, { useState } from "react";
import { TextField, Button, Box, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import "./CreateAccount.css";

// Import funkcji postAccountCreate
import { postAccountCreate } from "../../client/account";
import { CreateAccountRequest } from '../../model';

const CreateAccount: React.FC = () => {
  const navigate = useNavigate();
  const [createAccountData, setCreateAccountData] = useState<{
    email: string;
    password: string;
    repeatPassword: string;
    acceptedTerms: boolean;
  }>({
    email: '',
    password: '',
    repeatPassword: '',
    acceptedTerms: false,
  });

  // Funkcja obsługi wysyłania formularza
  const handleCreateAccount = async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    // Sprawdzenie, czy użytkownik zaakceptował regulamin
    if (!createAccountData.acceptedTerms) {
      alert("You must accept the terms and conditions to create an account.");
      return;
    }
    
    // Sprawdzenie, czy hasła są identyczne
    if (createAccountData.password !== createAccountData.repeatPassword) {
      alert("Passwords do not match.");
      return;
    }
    
    try {
      const createAccountRequest: CreateAccountRequest = {
        userEmail: createAccountData.email,
        password: createAccountData.password
      };

      // Wyślij dane konta na backend za pomocą postAccountCreate
      const response: AxiosResponse<void> = await postAccountCreate(createAccountRequest);
      
      console.log("Account created successfully:", response);

      // Zresetowanie formularza po sukcesie
      setCreateAccountData({
        email: '',
        password: '',
        repeatPassword: '',
        acceptedTerms: false,
      });

      // Przekierowanie na stronę logowania lub stronę główną
      navigate('/login');
    } catch (error) {
      console.error('Error creating account:', error);
      alert('There was an error creating your account. Please try again.');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setCreateAccountData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  return (
    <div className="page">
      <Box className="container">
        <Typography className="title">
          Create Account
        </Typography>
        <form onSubmit={handleCreateAccount}>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Email"
            type="email"
            name="email"
            value={createAccountData.email}
            onChange={handleInputChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Password"
            type="password"
            name="password"
            value={createAccountData.password}
            onChange={handleInputChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Repeat password"
            type="password"
            name="repeatPassword"
            value={createAccountData.repeatPassword}
            onChange={handleInputChange}
            required
          />
          <FormControlLabel
            control={
              <Checkbox
                name="acceptedTerms"
                checked={createAccountData.acceptedTerms}
                onChange={handleInputChange}
                required
              />
            }
            label="I accept the terms and conditions"
          />
          <div className="action-buttons">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleCreateAccount} // Obsługa kliknięcia przycisku
            >
              Create account
            </Button>
          </div>
        </form>
      </Box>
    </div>
  );
};

export default CreateAccount;
