// import '@arcgis/core/assets/esri/themes/dark/main.css';
import './styles/index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, Provider as ReduxProvider } from 'react-redux';

import AppContextProvider from './contexts/AppContextProvider';

import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home/Home';
import ProfilePage from './pages/Profile/Profile';
import LoginPage from './pages/Account/Login'
import CreateAccount from './pages/Account/CreateAccount'
import PredictionPage from './pages/Prediction/Prediction'

import { configureStore } from '@reduxjs/toolkit';

// import { authReducer } from './store/auth.slice';
// import { usersReducer } from './store/users.slice';

export * from './store/auth.slice';
// export * from './store/users.slice';

import { store } from './store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Header } from '@components/Header';
import Footer from '@components/Footer/Footer';
import { UserContextProvider } from '@components/Shared/UserContext';
import PrivateRoute from '@components/Shared/PrivateRoute';

(async () => {

    const root = createRoot(document.getElementById('root'));
    const queryClient = new QueryClient();

    root.render(
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <UserContextProvider>
                        <Header />
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route path="/create-account" element={<CreateAccount />} />
                            <Route path="/ai-prediction" element={<PredictionPage />} />

                            {/* <Route 
                                path="/profile" 
                                element={
                                    <PrivateRoute>
                                        <ProfilePage />
                                    </PrivateRoute>
                                }
                            /> */}
                        </Routes>
                        <Footer />
                    </UserContextProvider>
                </BrowserRouter>
            </QueryClientProvider>
);

})();
