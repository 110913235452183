/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import "./Footer.css";
import { CSSTransition } from 'react-transition-group';
import { Link, useNavigate  } from "react-router-dom";

const Footer: React.FC = () => {
  const [isNavVisible, setNavVisibility] = useState<boolean>(false);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const mediaQuery: any = window.matchMedia("(max-width: 700px)");
    const handleMediaQueryChange = (mediaQueryEvent: MediaQueryListEvent) => {
      if (mediaQueryEvent.matches) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  const navigateToHomePage = () => {
    navigate('/');
  };

  return (
    <footer className="footer">
      <div className="footer-text" onClick={navigateToHomePage}>Copyright @ Piotr M</div>
        <nav className="Nav">
          {/* <Link className="link" to="/login">Login/Register</Link> */}
        </nav>
    </footer>
  );
};

export default Footer;