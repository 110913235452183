import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '../helpers';

// create slice

interface AuthState {
    user: any;
    error: any;
}

const name = 'auth';
const initialState: AuthState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
// const slice = createSlice({ name, {user:null, error: null}, {}, {} });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState(): AuthState {
    return {
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    };
}

function createReducers() {
    return {
        logout
    };

    function logout(state: AuthState) {
        console.log("--- logout ---");
        state.user = null;
        localStorage.removeItem('user');
        history.navigate('/login');
    }
}

function createExtraActions() {
    // const baseUrl = `${process.env.REACT_APP_API_URL}/users`;
    console.log("createExtraActions");
    const baseUrl = `http://localhost:5000`;

    return {
        login: login()
    };

    function login() {
        console.log("--- login ---");
        
        return createAsyncThunk(
            `${name}/login`,
            async ({ username, password }: { username: string; password: string }) =>
                await fetchWrapper.post(`${baseUrl}/account/token`, { username, password })
        );
    }
}

function createExtraReducers(): any {
    return {
        ...login()
    };

    function login() {
        const { pending, fulfilled, rejected } = extraActions.login;
        return {
            [pending.type]: (state: AuthState) => {
                state.error = null;
            },
            [fulfilled.type]: (state: AuthState, action: PayloadAction<any>) => {
                const user = action.payload;

                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;

                const { from } = history.location.state || { from: { pathname: '/' } };
                history.navigate(from);
            },
            [rejected.type]: (state: AuthState, action: PayloadAction<any>) => {
                state.error = action.payload;
            }
        };
    }
}