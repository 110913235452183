import React, { createContext, useContext, useState } from 'react';

// Typ definiujący kontekst
interface UserContextType {
  currentUser: any; // Dostosuj typ do swoich potrzeb
  setCurrentUserContext: (user: any) => void;
}

// Inicjalizacja kontekstu z undefined, aby sprawdzić poprawne użycie w hooku
const UserContext = createContext<UserContextType | undefined>(undefined);

// Provider kontekstu
export const UserContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUserContext] = useState<any>(null);

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUserContext }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook do użycia kontekstu
export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
};

export default UserContext;
